import React from 'react'
import {Page, Text, View, Document, StyleSheet, Image, Font} from '@react-pdf/renderer'
import {toAbsoluteUrl} from '../../../design/helpers'

// Register font
Font.register({
  family: 'Poppins',
  src: '/fonts/Poppins/Poppins-Regular.ttf',
  //src: '/fonts/OPTIYale-Script/OPTIYale-Script.ttf',
})

Font.register({
  family: 'PoppinsBold',
  src: '/fonts/Poppins/Poppins-SemiBold.ttf',
  //src: '/fonts/OPTIYale-Script/OPTIYale-Script.ttf',
})

Font.register({
  family: 'PoppinsLight',
  src: '/fonts/Poppins/Poppins-Light.ttf',
  //src: '/fonts/OPTIYale-Script/OPTIYale-Script.ttf',
})

Font.register({
  family: 'Luxurious',
  src: '/fonts/Luxurious_Script/LuxuriousScript-Regular.ttf',
  //src: '/fonts/OPTIYale-Script/OPTIYale-Script.ttf',
})

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: '#E4E4E4',
    flex: 1,
    //fontFamily: 'Poppins',
    fontFamily: 'Poppins',
  },
  backgroundImg: {
    position: 'absolute',
    width: '100%',
    marginHorizontal: 'auto',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
  section: {
    margin: 15,
    padding: 15,
    flexGrow: 1,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  sectionB: {
    margin: 25,
    padding: 25,
    flex: 1,
  },
  logo: {
    width: 200,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '80%',
  },
  name: {
    fontFamily: 'Luxurious',
    fontSize: 90,
    whiteSpace: 'nowrap',
  },
  smallfont: {
    fontSize: 13,
    fontFamily: 'PoppinsLight',
  },
  content: {
    fontSize: 18,
    marginHorizontal: 100,
    fontFamily: 'PoppinsLight',
    alignSelf: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  assinatura: {
    width: '100%',
  },
})

type Props = {
  name: string
}

// Create Document Component
const today = new Date()
const yyyy = today.getFullYear()
let mm = (today.getMonth() + 1).toString() // Months start at 0!
let dd = today.getDate().toString()

if (parseInt(dd) < 10) dd = '0' + dd
if (parseInt(mm) < 10) mm = '0' + mm

const formattedToday = dd + '/' + mm + '/' + yyyy

const firstLetterUpperCase = (word: string) => {
  const capitalized =
  word.charAt(0).toUpperCase()
  + word.slice(1)

  return capitalized
}

const trabalhaNome = (name: string) => {
  let length = name.length
  if (length > 30) {
    let quebra = name.split(' ')
    let ultimoIndex = quebra.length - 1

    let sobrenome = ' '
    for (let i = 1; i < ultimoIndex; i++) {
      if (quebra[i].length < 4) {
        sobrenome = sobrenome + quebra[i] + ' '
      } else {
        sobrenome = sobrenome + quebra[i][0] + '. '
      }
    }

    name = firstLetterUpperCase(quebra[0]) + sobrenome + firstLetterUpperCase(quebra[ultimoIndex])
  }

  return name
}

const PDFDocument: React.FC<React.PropsWithChildren<Props>> = ({name}) => {
  return (
    <Document>
      <Page size='A4' style={styles.page} orientation='landscape'>
        <Image src={toAbsoluteUrl('/media/logos/bg-cert.png')} style={styles.backgroundImg} />
        <View style={styles.section}>
          <Image style={styles.logo} src={toAbsoluteUrl('/media/logos/logo-dark.png')} />
          <Text style={{fontSize: 40, fontFamily: 'PoppinsBold'}}>Certificado</Text>
          <Text style={styles.smallfont}>Temos o orgulho de apresentar este certificado para:</Text>
          <Text style={styles.name}>{trabalhaNome(name)}</Text>

          <Text style={styles.content}>
            concluiu o Treinamento 3FIOS® Online realizado nos dias 27 e 28 de Abril de
            2024 com carga horária de 22 horas ministrado pela Dra. Vanessa Defelícibus.
          </Text>
          <View style={{height: 40}} />
          <View style={styles.row}>
            <View style={{marginRight: 15, flexDirection: 'column', alignItems: 'center', flex: 1}}>
              <Image
                src={toAbsoluteUrl('/media/logos/assinatura-vanessa.png')}
                style={styles.assinatura}
              />
              <Text style={styles.smallfont}>Dra. Vanessa Defelícibus</Text>
            </View>

            <View style={{marginLeft: 15, flexDirection: 'column', alignItems: 'center', flex: 1}}>
              <Image src={toAbsoluteUrl('/media/logos/assinatura.png')} style={styles.assinatura} />
              <Text style={styles.smallfont}>{trabalhaNome(name)}</Text>
            </View>
          </View>
          <View style={{height: 20}} />
          <Text style={{fontSize: 8}}>Emitido em: {formattedToday}</Text>
        </View>
      </Page>

      <Page size='A4' style={styles.page} orientation='landscape'>
        <View style={styles.sectionB}>
          <Text style={{fontSize: 25, fontFamily: 'PoppinsBold'}}>Atividades extras</Text>
          <Text style={styles.smallfont}>
            - Treinamento Intercorrência Facial. Carga horária: 12h.
          </Text>
          <Text style={styles.smallfont}>- Treinamento Fissura Labial. Carga horária: 12h.</Text>
          <Text style={styles.smallfont}>- Treinamento Marketing e Vendas. Carga horária: 8h.</Text>
          <Text style={styles.smallfont}>- Workshop 3FIOS. Carga horária: 8h.</Text>
          <View style={{height: 30}} />
          <Text style={{fontSize: 25, fontFamily: 'PoppinsBold'}}>Carga horária total</Text>
          <Text style={styles.smallfont}>
            - Treinamento 3FIOS Online com atividades extras. Carga horária total: 62h.
          </Text>
        </View>
      </Page>
    </Document>
  )
}

export default PDFDocument
