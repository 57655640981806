import React, {FC, useEffect, useRef} from 'react'
import {PageLink, PageTitle} from '../../../design/layout/core'
// import {useIntl} from 'react-intl'
import {useSelector, useDispatch} from 'react-redux'
import {ApplicationState} from '../../../../store'
import {loadComponentWithAccessRequest} from '../../../../store/ducks/component/actions'
//import ReactPDF from '@react-pdf/renderer'
import PDFDocument from './PDFDocument'
import {PDFDownloadLink, Document, Page} from '@react-pdf/renderer'

const Certificate: FC<React.PropsWithChildren<unknown>> = () => {
  // const intl = useIntl()
  const component = useSelector((state: ApplicationState) => state.component)
  const me = useSelector((state: ApplicationState) => state.me)

  useEffect(() => {
    console.log('ID', me.me.id?.toString()!)
    dispatch(loadComponentWithAccessRequest('2', me.me.id?.toString()!, 'asc'))
  }, [])

  console.log('COMPONENT***', component)

  const dispatch = useDispatch()
  const breadCrumbs: Array<PageLink> = [
    {
      title: 'Todos os cursos',
      path: '/onlinecourses',
      isSeparator: false,
      isActive: false,
    },
  ]

  // let today = MOMENT().format( 'YYYY-MM-DD HH:mm:ss.000' );
  return (
    <>
      {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.MODULES'})} </PageTitle> */}
      <PageTitle breadcrumbs={breadCrumbs}>Certificado</PageTitle>
      <>
        <div>
          {me.me?.nameCertificate ? (
            <PDFDownloadLink
              document={<PDFDocument name={me.me?.nameCertificate!} />}
              fileName='certificado-3fios.pdf'
            >
              {({blob, url, loading, error}) =>
                loading
                  ? 'Preparando certificado...'
                  : 'Clique aqui para fazer o download do seu certificado.'
              }
            </PDFDownloadLink>
          ) : (
            'Seu certificado ainda não está pronto! Caso acredite que isso seja um erro, contate os administradores.'
          )}
        </div>

        {/* begin::Row */}
        {/* <div className='row g-5 gx-xxl-12'>
          <div className='col-xxl-12'>
            <div className='card-xxl-stretch mb-5 mb-xxl-8'>
              Certificamos que {me.me.name} concluiu os seguintes treinamentos:
              <br />
              <br />
              {component.data.children?.map((course) => (
                <div key={course.id}>
                  <p>{course.name}</p>
                </div>
              ))}
            </div>
          </div>
        </div> */}
        {/* end::Row */}
      </>
    </>
  )
}
export {Certificate}
